@import "base/variablesExt";
@import "@/assets/styles/base/buttons";

:root {
  --primary-color: #{$primary-color-rtk};
  --primary-color-rgb: #{$primary-color-rgb-rtk};
  --pc: #{$primary-color-rtk};
}

.rt-felix-button__container {
  &.outline {
    .rt-felix-button.loading .rt-felix__background-img {
      @include button-loader('%237700ff', !important);
    }

    .rt-felix-button.finished .rt-felix__background-img {
      @include button-finished('%237700ff', !important);
    }

    .rt-felix-button.error .rt-felix__background-img {
      @include button-error('%237700ff', !important);
    }
  }
}